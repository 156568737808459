
export enum IdentityType {
  None = 0,
  Employee = 1,
  Doctor = 2,
  Patient = 3,
  Visitor = 4,
  Guardian = 5,
  Admin = 6
}

export enum Occupation {
  Doctor = 'Doctor',
  Patient = 'Patient'
}
export enum PresenceState {
  Online = 'online',
  Busy = 'busy',
  Offline = 'offline'
}
export enum MedicalRecordType {
  OnlineAppointment = 1,
  WalkingAppointment = 2,
  Report = 3
}
export enum SearchTypes {
  All = 0,
  Name,
  Degree,
  Specialty,
  Location
}
export enum ODataJoinOperator {
  And = 'and',
  Or = 'or',
}
export enum SurveyQuestionTypeEnum {
  None = 0,
  TrueFalse = 1,
  MCQSingleCorrectAnswer = 2,
  MCQMultipleCorrectAnswers = 3,
  YesNo = 4,
  Textarea = 5
}
