<header>
  <div _ngcontent-ccp-c109="" class="top-header container-fluid"
    *ngIf="!identity || (identity && identity.identityTypeLookup == 2 && identity.isPublished) || (identity && identity.identityTypeLookup != 2)">
    <div _ngcontent-ccp-c109="" class="d-flex justify-content-between top-header-ul">
      <ul _ngcontent-ccp-c109="" class="d-flex justify-content-between align-items-center">
        <li _ngcontent-ccp-c109=""><i _ngcontent-ccp-c109="" class="fa fa-user-md fas"> A complete health care solution
            for health practitioners, patients and general public. </i></li>
        <li _ngcontent-ccp-c109="" *ngIf="CurrentLocation"><i _ngcontent-ccp-c109="" class="fa fa-map-marker fas">
            {{CurrentLocation.formatted_address}} </i></li>
      </ul>
      <ul _ngcontent-ccp-c109="" class="d-flex justify-content-between align-items-center">

        <li _ngcontent-ccp-c109="" style="size: 2px; font-style: italic;">
          {{ currentDateTime | date: 'dd/mm/yyyy hh:mm:ss a'}}
        </li>
        <div class="d-flex">
          <li class="noti-item mx-2">
            <i class="fa fa-bell">
              <span class="badge badge-danger" *ngIf="unreadcount>0">{{ unreadcount < 10 ? '0' + unreadcount
                  :unreadcount}}</span>
            </i>
          </li>

          <li class="mx-2">
            <span data-toggle="tooltip" data-placement="top" title="site tour" (click)="onClick()"><i
                class="fa fa-sitemap" aria-hidden="true"></i></span>
          </li>

          <li>
            <div id="google_translate_element">
              <div style="color:red; margin-bottom:-20px;">
              </div>
            </div>
            <div class="abx">
              <div class="dropdownn mx-2">
                <i data-toggle="tooltip" data-placement="top" title="select language" style="color: white;"
                  class="fa fa-globe" aria-hidden="true"></i>
                <div class="dropdown-contentt">
                  <a *ngFor="let item of languages" href="#" class="flag_link" data-lang="{{item.code}}"
                    (click)="SelectValue(item.code)">{{item.name}}</a>
                </div>
              </div>
            </div>
            <div class="hover_drop_down">
            </div>
          </li>
        </div>

      </ul>
    </div>
  </div>
  <div class="unpublish-strip container-fluid" *ngIf="identity && identity.identityTypeLookup == 2 && !identity.isPublished">
    <!-- <div _ngcontent-ccp-c109="" class="container-fluid"> -->
    <div class="d-flex justify-content-between">
        <p class="mb-0"><i class="fa fa-user-md"> In order to publish your profile for public, admin will review your details and
            reach out to you if further information is needed.
            Make sure you have submitted all  <a [routerLink]="['/profile', identity && identity.identityID ? identity.identityID : '']">Information</a>.

          </i></p>
          <ul class="d-flex justify-content-between align-items-center mb-0 text-white" style="list-style-type: none;">
            <li class="mx-2">
            <span data-toggle="tooltip" data-placement="top" title="site tour" (click)="onClick()"><i
                class="fa fa-sitemap" aria-hidden="true"></i></span>
          </li>

            <li>
              <div id="google_translate_element">
                <div style="color:red; margin-bottom:-20px;">
                </div>
              </div>
              <div class="abx">
                <div class="dropdownn mx-2">
                  <i data-toggle="tooltip" data-placement="top" title="select language" style="color: white;"
                    class="fa fa-globe" aria-hidden="true"></i>
                  <div class="dropdown-contentt">
                    <a *ngFor="let item of languages" href="#" class="flag_link" data-lang="{{item.code}}"
                      (click)="SelectValue(item.code)">{{item.name}}</a>
                  </div>
                </div>
              </div>
              <div class="hover_drop_down">
              </div>
            </li>
          </ul>
    </div>
  </div>
  <nav class="navbar navbar-icon-top navbar-expand-xl navbar-static-top navbar-dark bg-light container-fluid">
    <a class="navbar-brand" [routerLink]="['/']"><img class="logo-img" src="/assets/logo.png" alt=""></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" [ngClass]="{'active': currentState('home')}">
          <a class="nav-link" [routerLink]="['/']" routerLinkActive="router-link-active">
            <i class="fa fa-home"></i>
            Home
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item" [ngClass]="{'active': currentState('chat')}" joyrideStep="firstStep" title="Page Title"
          text="Main title!">
          <a class="nav-link" [routerLink]="['/chat']" routerLinkActive="router-link-active">
            <i class="fa fa-inbox" *ngIf="unreadMessages<1"></i>
            <i class="fa fa-envelope-o" *ngIf="unreadMessages>1">
              <span class="badge badge-danger" *ngIf="unreadcount>0">{{ unreadcount < 10 ? '0' + unreadcount
                  :unreadcount}}</span>
            </i>
            Chat
          </a>
        </li>
        <li class="nav-item dropdown" *ngIf="hasValidAuthenticationToken">
          <a class="nav-link dropdown-toggle" [ngClass]="{'active': currentState('appointment')}" id="navbarDropdown"
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-user-md" aria-hidden="true">
              <span class="badge badge-warning" *ngIf="appointmentCount>0">{{ appointmentCount < 10 ? '0' +
                  appointmentCount :appointmentCount}}</span>
            </i>
            Appointment
          </a>
          <div class="dropdown-menu dropdown-content" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="['/appointment']" [queryParams]="{page:'pending'}"
              routerLinkActive="router-link-active">Appointment Dashboard</a>
            <a class="dropdown-item" [routerLink]="['/make-appointment']" routerLinkActive="router-link-active">Make
              Appointment</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" [routerLink]="['/chat']" routerLinkActive="router-link-active">Chat Box</a>
          </div>
        </li>
        <li class="nav-item dropdown" joyrideStep="secondStep" title="Page Title" text="Main title!">
          <a class="nav-link dropdown-toggle" [ngClass]="{'active': currentState('blog')}" id="navbarDropdown"
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-rss-square" aria-hidden="true">
              <span class="badge badge-primary" *ngIf="blogCount>0">{{ blogCount < 10 ? '0' + blogCount
                  :blogCount}}</span>
            </i>
            Blogs
          </a>
          <div class="dropdown-menu dropdown-content" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="['/blog']" routerLinkActive="router-link-active">View Blogs</a>
            <a *ngIf="hasValidAuthenticationToken" class="dropdown-item" [routerLink]="['/addblog']"
              routerLinkActive="router-link-active">Add New Blog</a>
          </div>
        </li>
        <li *ngIf="identityTypeLookup === IdentityType.Doctor && hasValidAuthenticationToken" class="nav-item"
          [ngClass]="{'active': currentState('prefrence')}">
          <a class="nav-link" [routerLink]="['/prefrence']" [queryParams]="{page:'medication'}"
            routerLinkActive="router-link-active">
            <i class="fa fa-medkit" aria-hidden="true">

            </i>
            Prefrence
          </a>
        </li>
        <li class="nav-item" [ngClass]="{'active': currentState('contact')}">
          <a class="nav-link" [routerLink]="['/contact']" routerLinkActive="router-link-active">
            <i class="fa fa-telegram" aria-hidden="true"></i>
            Contact
          </a>
        </li>
        <li class="nav-item" [ngClass]="{'active': currentState('about')}">
          <a class="nav-link" [routerLink]="['/about']" routerLinkActive="router-link-active">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
            About
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" [ngClass]="{'active': currentState('survey')}" id="navbarDropdown"
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-deaf" aria-hidden="true">
              <!-- <span class="badge badge-primary"
                  *ngIf="blogCount>0">{{ blogCount < 10 ?  '0' + blogCount :blogCount}}</span> -->
            </i>
            Survey
          </a>
          <div class="dropdown-menu dropdown-content" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="['/survey/surveyList']" routerLinkActive="router-link-active">View
              Survey List</a>
            <a *ngIf="hasValidAuthenticationToken" class="dropdown-item" [routerLink]="['/survey/add']"
              routerLinkActive="router-link-active">Add New Survey</a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav ">
        <!-- <li class="nav-item">
            <a class="nav-link" href="#">
              <i class="fa fa-globe">
                <span class="badge badge-success">11</span>
              </i>
            </a>
          </li> -->
        <li class="nav-item" *ngIf="!hasValidAuthenticationToken">
          <a class="nav-linkk" [routerLink]="['/login']">
            <button class="btn btn-outline-primary my-2 my-sm-0 login-btn">Login <i class="fa fa-sign-in"
                aria-hidden="true"></i></button>
          </a>
        </li>
        <li *ngIf="hasValidAuthenticationToken && identity" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" [ngClass]="{'active': currentState('profile')}" id="navbarDropdown"
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

            <img src="{{getProfilePhoto}}" class="header-user-img">
            {{ ( identity && identity.identityTypeLookup == 2 ? "Dr. ": "")}}
            {{ ( identity && identity.identityTypeLookup === 3 && identity.genderTypeID === 1 ? "Mr. ": "")}}
            {{ ( identity && identity.identityTypeLookup === 3 && identity.genderTypeID === 2 ? "Ms. ": "")}}
            {{ identity && identity.firstname ? identity.firstname : '' }}
            {{ identity && identity.firstname ? identity.lastname : ''}}
          </a>
          <div class="dropdown-menu dropdown-content" aria-labelledby="navbarDropdown">
            <a id="my-account" class="dropdown-item small-menu-item"
              [routerLink]="['/profile', identity && identity.identityID ? identity.identityID : '']"
              title="My Account">My Profile</a>
            <a id="my-account" class="dropdown-item small-menu-item"
              [routerLink]="['/changePassword', identity && identity.identityID ? identity.identityID : '']"
              title="Change Password">Change Password</a>
            <a (click)="onLogOutClick()" class="dropdown-item small-menu-item" title="Logout" id="btn-logout">Logout</a>
          </div>
        </li>
      </ul>

    </div>
  </nav>



  <!-- </div> -->
</header>
