<div class="footer mt-4">
  <div class="footer-top">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3">
          <div class="footer-widget footer-about">
            <div class="footer-logo">
              <img src="/assets/logo-1.png" class="img-size">
            </div>
            <div class="footer-about-content">
              <p> It is an online platform for doctors and patients where the patient can book appointments and discuss
                their Problems with doctors and doctor can provide services online as well in clinic
              </p>

            </div>
          </div>
        </div>
        <div class="col-lg-6 d-flex justify-content-around footer-links">
          <div class="footer-widget footer-menu">
            <h2 class="footer-title">For Patient</h2>
            <ul>
              <li>
                <i class="fa fa-angle-double-right forward-icon" aria-hidden="true"></i><a [routerLink]="['/search']" routerLinkActive="router-link-active">Search For
                  Doctors</a>
              </li>
              <li>
                <i class="fa fa-angle-double-right forward-icon" aria-hidden="true"></i><a [routerLink]="['/login']" routerLinkActive="router-link-active">Login</a>
              </li>
              <li>
                <i class="fa fa-angle-double-right forward-icon" aria-hidden="true"></i><a [routerLink]="['/login/register']" routerLinkActive="router-link-active">Register</a>
              </li>
              <li>
                <i class="fa fa-angle-double-right forward-icon" aria-hidden="true"></i><a [routerLink]="['/make-appointment']" routerLinkActive="router-link-active">Booking</a>
              </li>
              <li>
                <i class="fa fa-angle-double-right forward-icon" aria-hidden="true"></i><a [routerLink]="['/appointment']" routerLinkActive="router-link-active"> Dashboard</a>
              </li>
            </ul>
          </div>
          <div class="footer-widget footer-menu">
            <h2 class="footer-title">For Doctors</h2>
            <ul>
              <li>
                <i class="fa fa-angle-double-right forward-icon" aria-hidden="true"></i><a [routerLink]="['/search']" routerLinkActive="router-link-active">Search For
                  Doctors</a>
              </li>
              <li>
                <i class="fa fa-angle-double-right forward-icon" aria-hidden="true"></i><a [routerLink]="['/login']" routerLinkActive="router-link-active">Login</a>
              </li>
              <li>
                <i class="fa fa-angle-double-right forward-icon" aria-hidden="true"></i><a [routerLink]="['/login/register']" routerLinkActive="router-link-active">Register</a>
              </li>
              <li>
                <i class="fa fa-angle-double-right forward-icon" aria-hidden="true"></i><a [routerLink]="['/make-appointment']" routerLinkActive="router-link-active">Booking</a>
              </li>
              <li>
                <i class="fa fa-angle-double-right forward-icon" aria-hidden="true"></i><a [routerLink]="['/appointment']" routerLinkActive="router-link-active"> Dashboard</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="footer-widget footer-contact">
            <h2 class="footer-title">Contact Us</h2>
            <div class="footer-contact-info">
              <div class="footer-address">
                <span>
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                </span>
                <p>KPK IT Park office # 16 Mandian Abbottabad Pakistan</p>
              </div>
              <p>
                <i class="fa fa-phone" aria-hidden="true"></i>  + 92 (315) 44 88437
              </p>
              <p class="mb-0">
                <i class="fa fa-envelope" aria-hidden="true"></i>support@talk2doctor.com
              </p>
              <div class="social-icon mt-4">
                <ul>
                  <li>
                    <a href="https://www.facebook.com"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                  </li>
                  <li>
                    <a href="https://www.instragram.com"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                  </li>
                  <li>
                    <a href="https://www.dribbble.com"><i class="fa fa-dribbble" aria-hidden="true"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container-fluid">
      <div class="copyright">
        <div class="row">
          <div class="col-6">
            <div class="copyright-text">
              <p class="mb-0">@ 2020 Talk2Doctor . All rights reserved</p>
            </div>
          </div>
          <div class="col-6">
            <div class="copyright-menu">
              <ul class="policy-menu">
                <li class="white-text" >Terms and Conditions</li>
                <li class="white-text">Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
