import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import * as moment from 'moment';
import _ from 'lodash';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private pingTimeoutId: number = 0
  private keepAliveDelayMilliseconds = moment.duration(1, 'minute').asMilliseconds()

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    if (!request.url.includes('https')) {
      if (this.authService.getToken()) {
        request = this.addToken(request, this.authService.getToken())
      }
    }


    return next.handle(request).pipe(tap((httpEvent: HttpEvent<any>) => {
      this.getRollForwardTokenIfAvailable(httpEvent)
    }, (httpResponse: HttpResponse<any>) => {
      if (httpResponse instanceof HttpErrorResponse && httpResponse.status === 401) {
        // this.handle401Error(request, next)
      }
    }));

  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `JWT ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this.addToken(request, token));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  protected getRollForwardTokenIfAvailable(httpEvent: HttpEvent<any>) {
    if (!(httpEvent instanceof HttpResponse)) {
      return
    }

    const response: HttpEvent<any> = httpEvent
    if (!response.headers.get('X-Roll-Forward-Token')) {
      return
    }

    this.handleRolloverToken(response.headers.get('X-Roll-Forward-Token'))
  }

  protected handleRolloverToken(token: string) {
    this.storeJwtToken(token)

    clearTimeout(this.pingTimeoutId)
      this.pingTimeoutId = <any>setTimeout(() => {
        if(this.authService.getToken())
        {
        this.authService.ping()
        }
    }, this.keepAliveDelayMilliseconds)
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem('authenticatedByLoginToken', jwt);
  }
}
