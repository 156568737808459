import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {

  IPatientAppointmentHistoryData,
  IMedicalRecordResponse,
  IUpdateDoctorDashboardAppointmentRequest,
  ITakeAppointmentRequest,
} from '../../app/interfaces/appInterface';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { promise } from 'protractor';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {

  urls = {
    authenticationTokenPath: 'authentication/token',
    identityProfile: 'identity/profile',
    loginViaAuthService: 'authentication/authServiceLogin',
    deleteSessionPath: 'session',
    updaterole: 'authentication/updaterole',
    verifyAccount: 'identity/verifyAccount',
    verificationCode: 'authentication/sendVerificationCode',
    register: 'identity/register',
  };
  constructor(private httpClient: HttpClient, private router: Router, private appService: AppService) {
  }

  public getDoctorAppointmentHistories(
    identityId: number,
    query: string
  ): Promise<{
    items: any[];
    count: number;
    nextPageLink: string;
  }> {
    return this.httpClient
      .get<{
        items: any[];
        count: number;
        nextPageLink: string;
      }>(`${this.appService.apiUrl}appointment/doctor/${identityId}/appointments${query}`)
      .toPromise();
  }

  public getPatientAppointmentHistories(
    identityId: number,
    query?: string 
  ): Promise<{
    items: any[];
    count: number;
    nextPageLink: string;
  }> {
    return this.httpClient
      .get<{
        items: any[];
        count: number;
        nextPageLink: string;
      }>(`${this.appService.apiUrl}appointment/patient/${identityId}/histories` + query)
      .toPromise();
  }

  public getappointmentDetail(
    medicalRecordId: number
  ): Promise<IMedicalRecordResponse> {
    return this.httpClient
      .get<IMedicalRecordResponse>(
        `${this.appService.apiUrl}medicalrecord/${medicalRecordId}`
      )
      .toPromise();
  }

  public updateDoctorDashboardAppointment(
    request: IUpdateDoctorDashboardAppointmentRequest
  ): Promise<any> {
    return this.httpClient
      .put(`${this.appService.apiUrl}appointment/updateAppointment`, request)
      .toPromise();
  }

  public takeAppointment(request: ITakeAppointmentRequest)  {

    return this.httpClient
    .post(`${this.appService.apiUrl}appointment/takeAppointment`,request)
    .toPromise()
  }
  postFile(fileToUpload: File) {
    const endpoint = 'your-destination-url';
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.httpClient.post(endpoint, formData,)

}
}
