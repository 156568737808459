import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './main/header/header.component';
import { FooterComponent } from './main/footer/footer.component';
import { NgxUiLoaderConfig, NgxUiLoaderModule, POSITION, SPINNER } from 'ngx-ui-loader';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './Services/auth.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchPipe } from './search.pipe';
import { FormsModule } from '@angular/forms';
import { AppStartupService } from './Services/app-startup.service';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { BlogReducer, DoctorReducer, HomePageImagesReducer, TopDoctorsReducer, UserAddressReducer } from './ngrxStore/reducer/home.reducer';
import { environment } from 'src/environments/environment';
import { BlogEffects, DoctorEffects, HomePageImagesEffects, TopDoctorsEffects, UserAddressEffects } from './ngrxStore/effects/home.effect';
import { JoyrideModule } from 'ngx-joyride'

var config:NgxUiLoaderConfig ={
  bgsColor: "#2276cb",
  bgsOpacity: 0.5,
  bgsPosition:  POSITION.bottomCenter,
  bgsSize: 60,
  bgsType: SPINNER.squareLoader,
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: "#2276cb",
  fgsPosition: POSITION.centerCenter,
  fgsSize: 60,
  fgsType: SPINNER.threeStrings,
  gap: 24,
  logoPosition: POSITION.centerCenter,
  logoSize: 120,
  logoUrl: "/assets/map.ico",
  overlayColor: "rgba(241,239,239,0.8)",
  pbColor: "#2276cb",
  text: "Please Wait",
  textColor: "#2276cb",
  textPosition: POSITION.centerCenter,
}
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    SearchPipe,
  ],


  imports: [
    JoyrideModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    NgxUiLoaderModule.forRoot(config),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    StoreModule.forRoot({userSpecificAddress: UserAddressReducer,doctor: DoctorReducer,blog:BlogReducer,topDoctors: TopDoctorsReducer, hImage: HomePageImagesReducer}),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([UserAddressEffects,DoctorEffects,BlogEffects,TopDoctorsEffects,HomePageImagesEffects])

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AppStartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [AppStartupService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function initialize(appStartupService: AppStartupService) {
  return () => appStartupService.initialize();
}
