import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ICountSpecility, ITopRatedDoctors } from '../interfaces/appInterface';
import { HomePageImages, topRatedDoctor, userAddress } from '../ngrxStore/models/home.model';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  urls = {
    doctorsList: 'doctor/homepage',

  }
  apiKey= 'AIzaSyB1CaF3uz9Mv27pssBj4l-j5jc_-AQLrdU'
  public location  = {latitude:0, longitude:0}
  constructor( private httpClient: HttpClient, private appService: AppService) {

    new Observable<GeolocationPosition>((observer) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
          observer.next(position);
        }, (error: GeolocationPositionError) => {
          observer.error(error);
        });
      } else {
        observer.error('Geolocation not available');
      }
    })
    .subscribe(position =>
      {
        this.location = {
          longitude: position.coords.latitude,
          latitude: position.coords.longitude,
        }
      },
      (msg) =>{
      }
    );
  }

  public getDoctorsList(query: string = '',userLocation): Observable<topRatedDoctor> {

    return this.httpClient.get<topRatedDoctor>(
       `${this.appService.apiUrl}${this.urls.doctorsList}?lat=${this.location.latitude}&lng=${this.location.longitude}`
    )
  }


public getUserAddress(): Observable<userAddress> {
  return this.httpClient.get<userAddress>(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.location.longitude},${this.location.latitude}&key=${this.apiKey}
  `)
}

public getHeaderSlides(): Observable<HomePageImages>{
  return this.httpClient.get<HomePageImages>(`${this.appService.apiUrlV2}newt2d/getheaderslider`)
}


}
