
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';

const Urls = {
  unreadmessagenotification: 'chat/unreadmessagenotification',
  unreadmessagesofeach: 'chat/unreadmessagesofeach',
  markreadchat: 'chat/markreadchat'
}

@Injectable()
export class ChatService {

  public constructor(
    private httpclient: HttpClient,
    private appService: AppService
  ) {
  }

  public initiateCall(request: ICallDTO){
    return this.httpclient.post<ICallDTO>(`${this.appService.apiUrl}chat/makeacall`, request
    ).toPromise()
  }

  public closeCall(identityId:number){
    return this.httpclient.post<number>(`${this.appService.apiUrl}chat/closecall`, identityId
    ).toPromise()
  }

  public unreadMessageNotification(userId: number) {
    return this.httpclient.get<number>(
      `${this.appService.apiUrl}${Urls.unreadmessagenotification}?ReceiverId=`+userId)
  }
  public unreadMessagesOfEach(userId: number){
    return this.httpclient.get<number>(
      `${this.appService.apiUrl}${Urls.unreadmessagesofeach}?UserId=`+userId )
  }
  public markReadChat(userId: number, rid: number) {
    return this.httpclient.put(`${this.appService.apiUrl}${Urls.markreadchat}?sid=${userId}&rid=${rid}`,12)
}
}

export interface ICallDTO {
  appointmentId: number
  callerId: number
  calleeId: number
  peerId: string
}
