<!-- main-slider -->
<div class="sliderdiv p-0 m-0">
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide h-imgs">
      <img [src]=" 'data:image/png;base64,'+slide.image " alt="" class="img-size">
    </div>
  </ngx-slick-carousel>
</div>
<!-- search bar -->
<div class="row search-main mx-0 mt-5" joyrideStep="searchStep" title="Page Title" text="Main title!">
  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-8 search-back">
    <div class="row search-hd">
      <h2 class="search-h">Search Doctor & Make An Appointment</h2>
    </div>
    <div class="row search-back2 mt-4">
      <div class="col-12 p-0 m-0">
        <div class="ng-autocomplete">
          <ng-autocomplete [(ngModel)]="searchValue" [data]="data" [searchKeyword]="keyword"
            (selected)='selectEvent($event)' (keydown.enter)='SearchEnter()' (inputChanged)='onChangeSearch($event)'
            (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
            [placeholder]="place">
          </ng-autocomplete>
          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item"></a>
          </ng-template>
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
        <span class="search-btn" (click)="Search()"><i class="fa fa-search" aria-hidden="true"></i></span>
      </div>
    </div>
  </div>
</div>
<!-- extra info  -->
<div class="banner-bottom">
  <div class="container">
    <div class="row">
      <div class="col-md-4 mt-4">
        <div class="card extra-height">
          <div class="care-text">
            <h2>Find Doctor</h2>
            <p>{{ 'You can find best doctor according to your search criteria: Doctor'+ 's Name, Doctor' +
              's Location, Speciality and related information' | substring : [subString1,false]}}
              <span class="moreOrLess" (click)="showFullString(1)" *ngIf="subString1 < 71">more</span>
              <span class="moreOrLess" (click)="showLessString(1)" *ngIf="subString1 > 70">less</span>
            </p>
          </div>
          <div>
            <img [src]="topCardImages.length ? 'data:image/png;base64,'+topCardImages[0].image : '' " alt=""
              class="img-size" style="color: white;">
          </div>
        </div>
      </div>
      <div class="col-md-4 mt-4">
        <div class="card">
          <div class="care-text">
            <h2>Appointment System</h2>
            <p>{{'The automated appointment system not only shares location and other details but helps in scheduling'+'
              the appointment in the optimized way for both patients'+'
              and doctors which saves time alot of hassle.' | substring : [subString2,false]}}
              <span class="moreOrLess" (click)="showFullString(2)" *ngIf="subString2 < 71">more</span>
              <span class="moreOrLess" (click)="showLessString(2)" *ngIf="subString2 > 70">less</span>
            </p>
          </div>
          <div>
            <img [src]="topCardImages.length ? 'data:image/png;base64,'+topCardImages[1].image : '' " alt=""
              class="img-size">
          </div>
        </div>
      </div>
      <div class="col-md-4 mt-4">
        <div class="card extra-height">
          <div class="care-text">
            <h2>Automation of Diagnosis and Prescription:</h2>
            <p>{{ 'Along with many other customized software modules for doctors T2D offers customization of diagnosis' + '
              and related medicines for doctors. The whole'
              + 'prescription can be generated at one click.' | substring : [subString3,false] }}
              <span class="moreOrLess" (click)="showFullString(3)" *ngIf="subString3 < 51">more</span>
              <span class="moreOrLess" (click)="showLessString(3)" *ngIf="subString3 > 50">less</span>
            </p>
          </div>
          <div>
            <img [src]="topCardImages.length ? 'data:image/png;base64,'+topCardImages[2].image : '' " alt=""
              class="img-size">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- top listed doctor -->
<div class="container-fluid section-doctor">
  <div class="section-header text-center">
    <h4 class="mainhead">Book Our Best Doctor</h4>
    <span>These doctors have gained better reviews by their clients </span>
  </div>
  <div class="container mt-5">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="doctorSlide">
      <div ngxSlickItem *ngFor="let doctor of topRatedDoctors">
        <div class="">
          <div class="profile-widget ">
            <div *ngIf="!doctor.isPublished" class="doctor-unpublish" >
              <small >Awaiting Publication</small>
            </div>
            <div class="doc-img">
              <img
                src="{{doctor.profileImageUrl ? covertPhotoUrl(doctor.profileImageUrl): doctor.genderTypeLookup == 1 ? '/assets/doctor-male.png' : '/assets/doctor.png'}}"
                alt="" class="">
              <a><i class="fa fa-bookmark fav-btn" aria-hidden="true"></i></a>
            </div>
            <div class="pro-content mt-4">
              <h2 class="title">{{doctor.doctorName}}</h2>
              <p class="specility" style="font-weight: 100; font-size: 13px; line-height: 21px;; margin-bottom:5px "
                >
                <i class="fa fa-user-md" aria-hidden="true"></i>{{ doctor.latestDegreeTitle}}
              </p>
              <!-- <p class="specility" style="font-weight: 100; font-size: 13px; line-height: 21px;; margin-bottom:5px "
                *ngIf="!doctor.latestDegreeTitle">
                <i class="fa fa-user-md" aria-hidden="true"></i> Dentist
              </p> -->
              <p class="specility" style="font-weight: 100; font-size: 13px; line-height: 21px; ; margin-bottom:5px " >
                <i class="fa fa-money" aria-hidden="true"></i>
                {{'Consultation Fee: PKR ' +  doctor.consultationFee}}
              </p>
              <!-- <p class="specility" style="font-weight: 100; font-size: 13px; line-height: 21px; margin-bottom:5px " *ngIf="!doctor.consultationFee">
                <i class="fa fa-money" aria-hidden="true"></i>
                Consultation Fee: PKR 200
              </p> -->
              <div class="rating">
                <i class="fa fa-star" aria-hidden="true" [ngClass]="{'filled':doctor.doctorStarRatting > 0}"></i>
                <i class="fa fa-star" aria-hidden="true" [ngClass]="{'filled':doctor.doctorStarRatting > 1}"></i>
                <i class="fa fa-star" aria-hidden="true" [ngClass]="{'filled':doctor.doctorStarRatting > 2}"> </i>
                <i class="fa fa-star" aria-hidden="true" [ngClass]="{'filled':doctor.doctorStarRatting > 3}"></i>
                <i class="fa fa-star" aria-hidden="true" [ngClass]="{'filled':doctor.doctorStarRatting > 4}"></i>
              </div>

              <!-- <div>
                <span class="mt-3"> <i class="fa fa-clock-o" aria-hidden="true"></i>
                  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let day of doctor.clinicDays" class="slide h-imgs">
                      <p> <strong>{{day.name}}</strong> </p>
                      <strong>From:</strong>{{day.clinicStartTime? getLocalTime(day.clinicStartTime): 'OFF'}}
                      <strong>To:</strong>{{day.clinicEndTime? getLocalTime(day.clinicEndTime): 'OFF'}}
                    </div>
                  </ngx-slick-carousel>
                </span>
              </div> -->


              <!-- <ul class="available-info">

                <li class="mt-3" *ngFor="let day of doctor.clinicDays"><i class="fa fa-clock-o" aria-hidden="true"></i>{{day.name}}:
                  {{day.clinicEndTime | date : 'hh:mm a' }} - {{  day.clinicStartTime | date: 'hh:mm a' }}</li>

                  <li class="mt-3" *ngIf="!doctor.clinicDays.length"><i class="fa fa-clock-o" aria-hidden="true"></i> Available Friday 2 PM
                    {{doctor.availabletime}}</li>

              </ul> -->
              <div class="row row-sm mt-3">
                <div class="col-6">
                  <a [routerLink]="['/doctorProfile',doctor.identityID]" routerLinkActive="router-link-active"
                    style="text-decoration: none"><button class="view-btn">Profile</button></a>
                </div>
                <div class="col-6">
                  <button class="book-btn" (click)="bookAppointment(doctor.identityID)">Book</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>


<!-- doctor specility -->
<div class="container">
  <div class="text-center mt-4 mb-4 speclity">
    <h4 class="mainhead animated bounce infinite">Browse By Speciality</h4>
    <span>We can demonstrate the doctors by speciality for easiness to find the required doctor for you </span>
  </div>
  <div class="row">
    <div class="col-lg-3 wow fadeInUp fadeInDown fadeInDownBig" data-wow-delay="0.6s"
      *ngFor=" let specility of countspecility">
      <a ui-sref="doctor-list({specility: specility.name })" [routerLink]="['/search/result']"
        [queryParams]="{speciality:specility.name }" style="text-decoration: none">
        <div class="category-box">
          <div class="category-image">
            <img src="/assets/cardiologist.png" alt="">

          </div>
          <div class="category-content">
            <h4>{{specility.name}}</h4>
            <span>{{specility.totalDoctors}} Doctors</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>

<div class="container-fluid" style="background-color: #fff ; padding: 80px 0">

  <div class="container-fluid" style="padding-left:30px ; padding-right: 30px">
    <div class="row">
      <div class="col-md-5">
        <img src="/assets/assets/slides/3.png" style="
        width: 100%;
        height: 100%;
    " alt="" class="feature-img wow lightSpeedIn" data-wow-delay="0.6s">
      </div>
      <div class="col-md-7">
        <div class="feature-header">
          <h2>Available Features</h2>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when
            looking at its layout.</p>
        </div>
        <div class="feature-slider">
          <div class="row">
            <div class="col-1">
              <i class="fa fa-chevron-left" style="margin-top:80px;    width: 1.625rem;
                    height: 1.625rem;" (click)="toggletabmminus(showTab)" disabled="showtab ==1"></i>

            </div>
            <div class="col-9">

              <!-- 1st item -->
              <div *ngIf="showTab == 1" class="">

                <div class="service-sec">
                  <div class="icon">

                    <i class="icon-chat"></i>

                  </div>
                  <div class="detail">
                    <h5>Talk to Doctors</h5>
                    <p>
                      If you need medical advice you can locate a doctor using search features and talk to him online.
                      Remember we recommend online
                      advice from only doctors with grade 4 or above on our Panel

                    </p>
                  </div>
                </div>
              </div>
              <!-- 2nd item -->
              <div *ngIf="showTab == 2" class="">
                <div class="service-sec">
                  <div class="icon">

                    <i class="icon-calendar"></i>

                  </div>
                  <div class="detail">
                    <h5>Online Appointment</h5>
                    <p>If for some reason you are not able to get walk-In appointment at the moment weoffer facility of
                      online
                      appointment where you can talk to your doctor online for further guidance.</p>
                  </div>
                </div>
              </div>
              <!-- 34d item -->
              <div *ngIf="showTab == 3" class="">
                <div class="service-sec">
                  <div class="icon">

                    <i class="icon-search"></i>

                  </div>
                  <div class="detail">
                    <h5>Search for a Doctor</h5>
                    <p>If you need a doctor for consultation using wide range of search options in our system.
                      Talk2Doctors
                      recomends doctors for you based on their complete profile and patients feedback.</p>
                  </div>
                </div>
              </div>
              <!-- 4th item -->
              <div *ngIf="showTab == 4" class="">
                <div class="service-sec">
                  <div class="icon">
                    <i class="icon-doctor"></i>
                  </div>
                  <div class="detail">
                    <h5>Doctors Database</h5>
                    <p>We have rich database of qualified doctors along with details about their qualification, clinic
                      location,
                      timing, contact details and patient's feedback.</p>
                  </div>
                </div>
              </div>
              <div class="carosal text-center">
                <i class="fa fa-minus fsize" aria-hidden="true" (click)="tabToggle(1)"
                  ng-class="{'active1':showTab == 1}"></i>
                <i class="fa fa-minus fsize" aria-hidden="true" (click)="tabToggle(2)"
                  ng-class="{'active1':showTab == 2}"></i>
                <i class="fa fa-minus fsize" aria-hidden="true" (click)="tabToggle(3)"
                  ng-class="{'active1':showTab == 3}"></i>
                <i class="fa fa-minus fsize" aria-hidden="true" (click)="tabToggle(4)"
                  ng-class="{'active1':showTab == 4}"></i>
              </div>

            </div>
            <div class="col-1">
              <i class="fa fa-chevron-right" style="margin-top:80px;    width: 1.625rem;
                        height: 1.625rem;" (click)="tabtoggleplus(showTab)" disabled="showtab == 4"></i>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</div>

<!-- blogs -->



<div class="container-fluid section-doctor">
  <div class="section-header text-center">
    <h4 class="mainhead">Blogs And News</h4>
    <span>Latest Blog Posted by Doctors to motivate the user by sharing intrested story </span>
  </div>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12" *ngFor="let blog of recentBlogPosts">
        <div class="blog grid-blog" [routerLink]="['/blogdetail',blog.blogID]">
          <div *ngIf="!blog.enabled" class="blog-unpublish" >
            <small >Awaiting Publication</small>
          </div>
          <div class="blog-image">
            <a>
              <img src="{{(blog.bannerImage)  ? covertPhotoUrl(blog.bannerImage) :'/assets/doctor.png'}}"
                class="img-fluid" alt="" style="height: 150px;">
            </a>
          </div>
          <div class="blog-content">
            <ul class="entry-meta meta-item">
              <li>
                <div class="post-author">
                  <a>
                    <span>{{blog.authorName}}</span>
                  </a>
                </div>
              </li>
              <li>
              <li class="mt-3"><i class="fa fa-clock-o" aria-hidden="true"></i>{{momentDate(blog.publishDate)}}
              </li>
            </ul>
            <h3 class="blog-title">
              <a>{{blog.title}}</a>
            </h3>
            <p class="mb-0">{{blog.article}}</p>
            <a routerLinkActive="router-link-active" class="read-more">Read More</a>
          </div>

        </div>

      </div>
    </div>
  </div>
  <div class="view-all text-center">
    <a [routerLink]="['/blog']" routerLinkActive="router-link-active" class="btn btn-primarys">View All</a>

  </div>

  <div *ngIf="hasIncomingCall" class="call-overlay">
    <i class="fa fa-facetime-video"></i>
    <span id="incoming-call">Incoming Call</span>
    <hr>
    <img id="accept-call" src="/assets/accept.png" (click)="accepteCall()">
    <img id="decline-call" src="/assets/decline.png" (click)="declineCall()">
  </div>
</div>
<agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
  <agm-marker [latitude]="latitude" [iconUrl]="iconUrl" [longitude]="longitude"></agm-marker>
</agm-map>
