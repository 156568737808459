import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError, take, delay } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { DoctorService } from 'src/app/Services/doctor.service';
import { BlogActionTypes, DoctorActionTypes, HomePageImagesActionTypes, LoadBlogAction, LoadBlogFailureAction, LoadBlogSuccessAction, LoadDoctorAction, LoadDoctorFailureAction, LoadDoctorSuccessAction, LoadHomePageImagesAction, LoadHomePageImagesFailureAction, LoadHomePageImagesSuccessAction, LoadTopDoctorsAction, LoadTopDoctorsFailureAction, LoadTopDoctorsSuccessAction, LoadUserAddressFailureAction, LoadUserAddressSuccessAction, TopDoctorsActionTypes, UserAddressAction, UserAddressActionTypes } from '../actions/home.action';
import { DoctorCard, HomePageImages, LatestBlog, topRatedDoctor, userAddress } from '../models/home.model';
import { BlogService } from 'src/app/Services/blog/blog.service';
import { HomeService } from 'src/app/Services/home.service';


@Injectable()
export class DoctorEffects {

  @Effect() loadDoctor$ = this.actions$
    .pipe(
      ofType<LoadDoctorAction>(DoctorActionTypes.LOAD_DOCTOR),
      mergeMap(
        () => this.doctorService.getDoctors()
          .pipe(
            map((data: DoctorCard) => {
              return new LoadDoctorSuccessAction(data)
            }),
            catchError(error => of(new LoadDoctorFailureAction(error)))
          )
      ),
      take(1)
    )
  constructor(
    private actions$: Actions,
    private doctorService: DoctorService
  ) { }
}

const query = `?$inlinecount=allpages&$top=10&$orderby=BlogID`
@Injectable()
export class BlogEffects {

  @Effect() loadDoctor$ = this.actions$
    .pipe(
      ofType<LoadBlogAction>(BlogActionTypes.LOAD_BLOG),
      mergeMap(
        () => this.blogService.getLatestBlogPosts(query)
          .pipe(
            map((data: LatestBlog) => {
              return new LoadBlogSuccessAction(data)
            }),
            catchError(error => of(new LoadBlogFailureAction(error)))
          )
      ),
      take(1)
    )
  constructor(
    private actions$: Actions,
    private blogService: BlogService
  ) { }
}

@Injectable()
export class UserAddressEffects {
  loadUserAddress$
  constructor(
    private actions$: Actions,
    private homeService: HomeService,
  ) {
    this.loadUserAddress$ = createEffect(() => this.actions$
        .pipe(
          ofType<UserAddressAction>(UserAddressActionTypes.LOAD_USERADDRESS),

          mergeMap(
                () => this.homeService.getUserAddress()
              .pipe(
                map((data: userAddress) => {
                  return new LoadUserAddressSuccessAction(data)
                }),
                catchError(error => of(new LoadUserAddressFailureAction(error)))
              )
          ),
          take(1)
          )
        )
  }


}
@Injectable()
export class TopDoctorsEffects {
  loadTopDoctor$
  constructor(
    private actions$: Actions,
    private homeService: HomeService,
  ) {
    this.loadTopDoctor$ = createEffect(() => this.actions$
        .pipe(
          ofType<LoadTopDoctorsAction>(TopDoctorsActionTypes.LOAD_TOPDOCTORS),

          mergeMap(
                () => this.homeService.getDoctorsList(' ', {
                  longitude: 0,
                  latitude: 0,
                })
              .pipe(
                map((data: topRatedDoctor) => {
                  return new LoadTopDoctorsSuccessAction(data)
                }),
                catchError(error => of(new LoadTopDoctorsFailureAction(error)))
              )
          ),
          take(1)
        ))
  }


}
@Injectable()
export class HomePageImagesEffects {
  loadHomepageImages$
  constructor(
    private actions$: Actions,
    private homeService: HomeService,
  ) {
    this.loadHomepageImages$ = createEffect(() => this.actions$
        .pipe(
          ofType<LoadHomePageImagesAction>(HomePageImagesActionTypes.LOAD_HOMEPAGEIMAGES),

          mergeMap(
                () => this.homeService.getHeaderSlides()
              .pipe(
                map((data: HomePageImages) => {
                  return new LoadHomePageImagesSuccessAction(data)
                }),
                catchError(error => of(new LoadHomePageImagesFailureAction(error)))
              )
          ),
          take(1)
        ))
  }


}

