import { BlogAction, BlogActionTypes, DoctorAction, DoctorActionTypes, HomePageImagesAction, HomePageImagesActionTypes, TopDoctorsAction, TopDoctorsActionTypes, UserAddressAction, UserAddressActionTypes } from "../actions/home.action";
import { DoctorCard, HomePageImages, LatestBlog, topRatedDoctor, userAddress } from "../models/home.model";


export interface DoctorState {
  list: DoctorCard,
  loading: boolean,
  error: Error
}

const initialState: DoctorState = {
  list: {doctors:[]},
  loading: false,
  error: undefined
};

export function DoctorReducer(state: DoctorState = initialState, action: DoctorAction) {
  switch (action.type) {
    case DoctorActionTypes.LOAD_DOCTOR:
      return {
        ...state,
        loading: true
      }
    case DoctorActionTypes.LOAD_DOCTOR_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false
      }

    case DoctorActionTypes.LOAD_DOCTOR_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    default:
      return state;
  }
}


//blog reducer

export interface BlogState {
  list: LatestBlog,
  loading: boolean,
  error: Error
}
const initialState2: BlogState = {
  list: {items:[],count:0},
  loading: false,
  error: undefined
};
export function BlogReducer(state: BlogState = initialState2, action: BlogAction) {
  switch (action.type) {
    case BlogActionTypes.LOAD_BLOG:
      return {
        ...state,
        loading: true
      }
    case BlogActionTypes.LOAD_BLOG_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false
      }

    case BlogActionTypes.LOAD_BLOG_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    default:
      return state;
  }
}

// toprated doctor

export interface TopDoctorsState {
  list: topRatedDoctor,
  loading: boolean,
  error: Error
}
const initialState3: TopDoctorsState = {
  list: {doctorsList:[],countDoctorBySpeciality:[]},
  loading: false,
  error: undefined
};
export function TopDoctorsReducer(state: TopDoctorsState = initialState3, action: TopDoctorsAction) {
  switch (action.type) {
    case TopDoctorsActionTypes.LOAD_TOPDOCTORS:
      return {
        ...state,
        loading: true
      }
    case TopDoctorsActionTypes.LOAD_TOPDOCTORS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false
      }

    case TopDoctorsActionTypes.LOAD_TOPDOCTORS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    default:
      return state;
  }
}



// userAddress

export interface UserAddressState {
  list: userAddress,
  loading: boolean,
  error: Error
}
const initialState4: UserAddressState = {
  list: {plus_code:{compound_code:'',global_code:''},results:[],status:''},
  loading: false,
  error: undefined
};
export function UserAddressReducer(state: UserAddressState = initialState4, action: UserAddressAction) {
  switch (action.type) {
    case UserAddressActionTypes.LOAD_USERADDRESS:
      return {
        ...state,
        loading: true
      }
    case UserAddressActionTypes.LOAD_USERADDRESS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false
      }

    case UserAddressActionTypes.LOAD_USERADDRESS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    default:
      return state;
  }
}


// homePageImages

export interface HomePageImagesState {
  list: HomePageImages,
  loading: boolean,
  error: Error
}
const initialState5: HomePageImagesState = {
  list: {homePageSliderList:[]},
  loading: false,
  error: undefined
};
export function HomePageImagesReducer(state: HomePageImagesState = initialState5, action: HomePageImagesAction) {
  switch (action.type) {
    case HomePageImagesActionTypes.LOAD_HOMEPAGEIMAGES:
      return {
        ...state,
        loading: true
      }
    case HomePageImagesActionTypes.LOAD_HOMEPAGEIMAGES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false
      }

    case HomePageImagesActionTypes.LOAD_HOMEPAGEIMAGES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    default:
      return state;
  }
}
