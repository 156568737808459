import { Injectable } from "@angular/core";
import { AppStartupService } from "./app-startup.service";
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class AppService {
  // Declare this key and iv values in declaration
  key = CryptoJS.enc.Utf8.parse('4512631236589784');
  iv = CryptoJS.enc.Utf8.parse('4512631236589784');

  constructor(private appStartupService: AppStartupService){
  }

  public get apiUrl() {
    return this.appStartupService.apiUrl
  }

  public get signalRHub() {
    return this.appStartupService.signalRHub
  }

  public get apiUrlV2(){
    return this.appStartupService.apiUrl2
  }

  public decryptUsingAES256(decString) {
    var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
        keySize: 128 / 8,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

  }

  public encryptUsingAES256(plainText) {

    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), this.key, {
        keySize: 128 / 8,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted;
}

}
