import { Action } from "@ngrx/store";
import { DoctorCard, HomePageImages, LatestBlog, topRatedDoctor } from "../models/home.model";


export enum DoctorActionTypes {
  LOAD_DOCTOR = '[DOCTOR] Load DOCTOR',
  LOAD_DOCTOR_SUCCESS = '[DOCTOR] Load DOCTOR Success',
  LOAD_DOCTOR_FAILURE = '[DOCTOR] Load DOCTOR Failure',

}
export enum BlogActionTypes {
  LOAD_BLOG = '[BLOG] Load BLOG',
  LOAD_BLOG_SUCCESS = '[BLOG] Load BLOG Success',
  LOAD_BLOG_FAILURE = '[BLOG] Load BLOG Failure',

}
export enum TopDoctorsActionTypes {
  LOAD_TOPDOCTORS = '[TOPDOCTORS] Load TOPDOCTORS',
  LOAD_TOPDOCTORS_SUCCESS = '[TOPDOCTORS] Load TOPDOCTORS Success',
  LOAD_TOPDOCTORS_FAILURE = '[TOPDOCTORS] Load TOPDOCTORS Failure',

}
export enum UserAddressActionTypes {
  LOAD_USERADDRESS = '[USERADDRESS] Load USERADDRESS',
  LOAD_USERADDRESS_SUCCESS = '[USERADDRESS] Load USERADDRESS Success',
  LOAD_USERADDRESS_FAILURE = '[USERADDRESS] Load USERADDRESS Failure',

}
export enum HomePageImagesActionTypes {
  LOAD_HOMEPAGEIMAGES = '[HOMEPAGEIMAGES] Load HOMEPAGEIMAGES',
  LOAD_HOMEPAGEIMAGES_SUCCESS = '[HOMEPAGEIMAGES] Load HOMEPAGEIMAGES Success',
  LOAD_HOMEPAGEIMAGES_FAILURE = '[HOMEPAGEIMAGES] Load HOMEPAGEIMAGES Failure',

}
export class LoadDoctorAction implements Action {
  readonly type = DoctorActionTypes.LOAD_DOCTOR
}
export class LoadDoctorSuccessAction implements Action {
  readonly type = DoctorActionTypes.LOAD_DOCTOR_SUCCESS

  constructor(public payload: DoctorCard) {}

}
export class LoadDoctorFailureAction implements Action {
  readonly type = DoctorActionTypes.LOAD_DOCTOR_FAILURE

  constructor(public payload: Error) {}
}
//blog action

export class LoadBlogAction implements Action {
  readonly type = BlogActionTypes.LOAD_BLOG
}
export class LoadBlogSuccessAction implements Action {
  readonly type = BlogActionTypes.LOAD_BLOG_SUCCESS

  constructor(public payload: LatestBlog) {}

}
export class LoadBlogFailureAction implements Action {
  readonly type = BlogActionTypes.LOAD_BLOG_FAILURE

  constructor(public payload: Error) {}
}

//toprated doctor
export class LoadTopDoctorsAction implements Action {
  readonly type = TopDoctorsActionTypes.LOAD_TOPDOCTORS
}
export class LoadTopDoctorsSuccessAction implements Action {
  readonly type = TopDoctorsActionTypes.LOAD_TOPDOCTORS_SUCCESS

  constructor(public payload: topRatedDoctor) {}

}
export class LoadTopDoctorsFailureAction implements Action {
  readonly type = TopDoctorsActionTypes.LOAD_TOPDOCTORS_FAILURE

  constructor(public payload: Error) {}
}



//useraddress
export class LoadUserAddressAction implements Action {
  readonly type = UserAddressActionTypes.LOAD_USERADDRESS
}
export class LoadUserAddressSuccessAction implements Action {
  readonly type = UserAddressActionTypes.LOAD_USERADDRESS_SUCCESS

  constructor(public payload: any) {}

}
export class LoadUserAddressFailureAction implements Action {
  readonly type = UserAddressActionTypes.LOAD_USERADDRESS_FAILURE

  constructor(public payload: Error) {}
}

//homePageImages
export class LoadHomePageImagesAction implements Action {
  readonly type = HomePageImagesActionTypes.LOAD_HOMEPAGEIMAGES
}
export class LoadHomePageImagesSuccessAction implements Action {
  readonly type = HomePageImagesActionTypes.LOAD_HOMEPAGEIMAGES_SUCCESS

  constructor(public payload: HomePageImages) {}

}
export class LoadHomePageImagesFailureAction implements Action {
  readonly type = HomePageImagesActionTypes.LOAD_HOMEPAGEIMAGES_FAILURE

  constructor(public payload: Error) {}
}

export type DoctorAction =
  LoadDoctorAction |
  LoadDoctorFailureAction |
  LoadDoctorSuccessAction

  export type BlogAction =
  LoadBlogAction |
  LoadBlogFailureAction |
  LoadBlogSuccessAction

  export type TopDoctorsAction =
  LoadTopDoctorsAction |
  LoadTopDoctorsFailureAction |
  LoadTopDoctorsSuccessAction



  export type UserAddressAction =
  LoadUserAddressAction |
  LoadUserAddressFailureAction |
  LoadUserAddressSuccessAction


  export type HomePageImagesAction =
  LoadHomePageImagesAction |
  LoadHomePageImagesSuccessAction |
  LoadHomePageImagesFailureAction
