import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IDoctorDegreeData,
  IDoctorExperienceData,
  IDoctorProfile,
  IDoctorSpecialityData,
  IUpdateDiagnosisPreference,
} from '../interfaces/appInterface';
import { DoctorCard } from '../ngrxStore/models/home.model';
import { IDoctorDiagnosisList, IMedicine } from '../prefrences/prefrences.component';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class DoctorService {

  urls = {};

  constructor(private httpClient: HttpClient, private appService: AppService) {}
  public loadDiagnosis(doctorId: number): Promise<any> {
    return this.httpClient
      .get<any>(`${this.appService.apiUrl}doctor/${doctorId}/getdiagnosispreferences`)
      .toPromise();
  }
  public loadPrescriptions(doctorId: number): Promise<any> {
    return this.httpClient
      .get<any>(`${this.appService.apiUrl}doctor/getdoctormedicationpreference/${doctorId}`)
      .toPromise();
  }

  public getDagnosisMedication(doctorId: number): Promise<any> {
    return this.httpClient
      .get(`${this.appService.apiUrl}doctor/${doctorId}/getdiagnosismedication`)
      .toPromise();
  }
  public updateDoctorDetail(doctorId: number, request: any) {
    const doctordata1 = { doctorsData: request };
    return this.httpClient.put(
      `${this.appService.apiUrlV2}newt2d/${doctorId}/updateDetail`,
      doctordata1
    );
  }

  public updateDoctorSpeciality(
    doctorSpecialty: IDoctorSpecialityData
  ): Promise<{}> {
    const request = {
      doctorSpecialityData: doctorSpecialty,
    };

    return this.httpClient
      .put<IDoctorSpecialityData>(
        `${this.appService.apiUrl}doctor/speciality/${doctorSpecialty.xrefDoctorSpecialityID}/update`,
        request
      )
      .toPromise();
  }

  public updateDoctorEperience(
    doctorExperience: IDoctorExperienceData
  ): Promise<{}> {
    const request = doctorExperience

    return this.httpClient
      .put<IDoctorSpecialityData>(
        `${this.appService.apiUrlV2}newt2d/updateexperience`,
        request
      )
      .toPromise();
  }

  public updateDoctorDegree(doctorDegree: IDoctorDegreeData): Promise<{}> {
    const request = { doctorDegreeData: doctorDegree };
    return this.httpClient
      .put<IDoctorDegreeData>(
        `${this.appService.apiUrl}doctor/degree/${doctorDegree.xrefDoctorDegreeID}/update`,
        request
      )
      .toPromise();
  }
  public createDoctorSpeciality(request: IDoctorSpecialityData) {
    const request1 = { doctorSpecialityData: request };
    return this.httpClient.post<IDoctorSpecialityData>(
      `${this.appService.apiUrl}doctor/createSpeciality`,
      request1
    );
  }
  public addDoctorExperience(request: IDoctorExperienceData) {
    return this.httpClient.post<IDoctorExperienceData>(
      `${this.appService.apiUrlV2}newt2d/addexperience`,
      request
    );
  }

  public createDoctorDegree(request: IDoctorDegreeData) {
    const request1 = { doctorDegreeData: request };
    return this.httpClient.post(this.appService.apiUrl + 'doctor/createDegree', request1);
  }

  public getDoctorSpecialities(
    doctorId: number
  ): Promise<IDoctorSpecialityData> {
    return this.httpClient
      .get<IDoctorSpecialityData>(`${this.appService.apiUrl}doctor/${doctorId}/specialities`)
      .toPromise();
  }

  public getDoctorExperience(
    doctorId: number
  ): Promise<IDoctorExperienceData> {
    return this.httpClient
      .get<IDoctorExperienceData>(`${this.appService.apiUrlV2}newt2d/${doctorId}/getexperience`)
      .toPromise();
  }

  public deleteDoctorSpeciality(
    xrefDoctorSpecialityID: number,
    doctorId: number
  ): Promise<{}> {
    return this.httpClient
      .delete(
        `${this.appService.apiUrl}doctor/speciality/${xrefDoctorSpecialityID}/${doctorId}/delete`
      )
      .toPromise();
  }

  public deleteDoctorExperience(
    experienceId: number,
    doctorId: number
  ): Promise<{}> {
    return this.httpClient
      .delete(
        `${this.appService.apiUrlV2}newt2d/experience/${experienceId}/${doctorId}/delete`
      )
      .toPromise();
  }

  public deleteDoctorDegree(
    doctorDegreeId: number,
    doctorId: number
  ): Promise<{}> {
    return this.httpClient
      .delete(`${this.appService.apiUrl}doctor/degree/${doctorDegreeId}/${doctorId}/delete`)
      .toPromise();
  }

  public getDoctorDegrees(doctorId: number): Promise<IDoctorDegreeData> {
    return this.httpClient
      .get<IDoctorDegreeData>(`${this.appService.apiUrl}doctor/${doctorId}/degrees`)
      .toPromise();
  }

  public getDoctors():Observable<DoctorCard>{
    return this.httpClient
      .get<DoctorCard>(`${this.appService.apiUrl}doctor/list`)
  }

  saveDoctorMedicialPrefrences(data){
    return this.httpClient.post(`${this.appService.apiUrl}doctor/addmedicationprefernces`,data).toPromise();

  }

  getDiagnosisPreferences(doctorId){
    return this.httpClient.get<IDoctorDiagnosisList[]>(`${this.appService.apiUrl}doctor/${doctorId}/getdiagnosispreferences`
    ).toPromise()

  }

  public getMedicationPreferences(doctorId){
    return this.httpClient
      .get<IMedicine>(`${this.appService.apiUrl}doctor/getdoctormedicationpreference/${doctorId}`)
      .toPromise();

  }

  public saveDoctorDiagnosisPreferences(data){
    return this.httpClient.post<IDoctorDiagnosisList[]>(`${this.appService.apiUrl}doctor/creatediagnosispreferences`,data ).toPromise()

  }

  public deleteDiagnosis(diagnosisId){
    return this.httpClient.delete<IDoctorDiagnosisList[]>(`${this.appService.apiUrl}doctor/diagnosis/${diagnosisId}/delete`
    ).toPromise()

  }
  public deleteMedicine(medicineId){
    return this.httpClient.delete(`${this.appService.apiUrl}doctor/medicine/${medicineId}/delete`
    ).toPromise()

  }
  public updateMedicine(data){
    return this.httpClient.put(`${this.appService.apiUrl}doctor/updatemedicine`,data
    ).toPromise()

  }
  public updateDiagnosis(request: IUpdateDiagnosisPreference):Observable<IDoctorDiagnosisList[]> {
    return this.httpClient.put<IDoctorDiagnosisList[]>(
    `${this.appService.apiUrl}doctor/updatediagnose`, request
    )
  }
  public getDoctorsNameList(){
    return this.httpClient
      .get<string[]>(`${this.appService.apiUrl}doctor/namelist`)
      .toPromise();
  }
  public getSuggestedSpecialityNameList(keyword: string){
    return this.httpClient
      .get<string[]>(`${this.appService.apiUrl}doctor/${keyword}/medicalSpecialityNameSuggestion`)
      .toPromise();
  }
  public getSuggestedBrandNameList(keyword: string){
    return this.httpClient
      .get<string[]>(`${this.appService.apiUrl}doctor/${keyword}/brandNameSuggestion`)
      .toPromise();
  }
  public getSuggestedGenericNameList(keyword: string){
    return this.httpClient
      .get<string[]>(`${this.appService.apiUrl}doctor/${keyword}/genericSuggestion`)
      .toPromise();
  }
  public getBrandListByGeneric(keyword: string){
    return this.httpClient
      .get<string[]>(`${this.appService.apiUrl}doctor/${keyword}/brandByGeneric`)
      .toPromise();
  }

  public getSuggestedMedicineDataByBrandName(brandName: string){
    return this.httpClient
      .get<string[]>(`${this.appService.apiUrl}doctor/${brandName}/medicineDataByBrandName`)
      .toPromise();
  }
}
