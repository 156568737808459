import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import _ from 'lodash';
import { IdentityService } from 'src/app/Services/identity.service';
import { IdentityType } from '../../enums/enums'
import { ILocation, IProfileData } from 'src/app/interfaces/appInterface';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { SignalrService } from 'src/app/Services/signalr/signalr.service';
import { Observable, Subscription } from 'rxjs';
import moment from 'moment';
import { AppointmentService } from 'src/app/Services/appointment.service';
import { BlogService } from 'src/app/Services/blog/blog.service';
import { ChatService } from 'src/app/Services/chat-service/chat-service';
import { userAddress } from 'src/app/ngrxStore/models/home.model';
import { isPlatformBrowser } from '@angular/common';
import { JoyrideService } from 'ngx-joyride';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ChatService],
  encapsulation: ViewEncapsulation.Emulated
})

export class HeaderComponent implements OnInit, OnDestroy {
  unreadMessages = 11;
  currentDateTime = moment().format()
  public isDropdownOpen = false;
  currenturl: string = '';
  public IdentityType = IdentityType
  public subscirption = new Subscription()
  public unreadcount: number
  appointmentCount: number = 0;
  blogCount: number = 0;
  UserAddressList: Observable<userAddress>;
  UserAddressloading$: Observable<Boolean>;
  UserAddresserror$: Observable<Error>;
  userData : IProfileData;
  store: any;
  CurrentLocation: Observable<any>;
  currentIdentityId: number;
  selectedLang: string = 'en';
  languages = [
    { name: 'Arabic', code: 'ar' },
    { name: 'Korean', code: 'ko' },
    { name: 'Portuguese', code: 'pt-BR' },
    { name: 'Bulgarian', code: 'bg' },
    { name: 'Croatian', code: 'hr' },
    { name: 'Czech', code: 'cs' },
    { name: 'Danish', code: 'da' },
    { name: 'Dutch', code: 'nl' },
    { name: 'Estonian', code: 'et' },
    { name: 'Filipino', code: 'fil' },
    { name: 'French', code: 'fr' },
    { name: 'German', code: 'de' },
    { name: 'Greek', code: 'el' },
    { name: 'Hindi', code: 'hi' },
    { name: 'Hungarian', code: 'hu' },
    { name: 'Icelandic', code: 'is' },
    { name: 'Indonesian', code: 'id' },
    { name: 'Italian', code: 'it' },
    { name: 'Japanese', code: 'ja' },
    { name: 'Malay', code: 'ms' },
    { name: 'Norwegian', code: 'no' },
    { name: 'Polish', code: 'pl' },
    { name: 'Portuguese', code: 'pt-PT' },
    { name: 'Romanian', code: 'ro' },
    { name: 'Russian', code: 'ru' },
    { name: 'Chinese', code: 'zh-CN' },
    { name: 'Spanish', code: 'es' },
    { name: 'Swedish', code: 'sv' },
    { name: 'Thai', code: 'th' },
    { name: 'Chinese', code: 'zh-TW' },
    { name: 'Turkish', code: 'tr' },
    { name: 'Urdu', code: 'ur' },
    { name: 'Ukrainian', code: 'uk' },
    { name: 'Vietnamese', code: 'vi' },
  ];

  constructor(private router: Router,
    private IdentityService: IdentityService,
    private authenticationService: AuthenticationService,
    private ref: ChangeDetectorRef,
    public appointmentService: AppointmentService,
    public blogService: BlogService,
    private signalRService: SignalrService,
    private chatService: ChatService,
    private readonly joyrideService: JoyrideService
  ) { }

  ngOnInit(): void {
    this.signalRService.initializeNotifcationHub();
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      if (event.url == '/') {
        this.currenturl = 'home'
      } else {
        this.currenturl = event.url
      }
    });
    this.subscirption = this.signalRService.pushNotification.subscribe(response => {

      this.unreadcount = response
    })
    if (this.identity) {
      this.getUnredMessageCounts(this.identity.identityID);
    }

    if (this.identity && this.identity.identityTypeLookup == 2) {
      this.countDoctorPendingAppointments()
    }
    if (this.identity && this.identity.identityTypeLookup == 3) {
      this.countPatientPendingAppointments()
    }
    const query = `?$inlinecount=allpages`
    this.countBlogs(query)

    this.getcurrentLocation()
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.currentIdentityId = this.userData ? this.userData.identityID : 0;
  }

  public onClick() {
      this.joyrideService.startTour(
          { steps: ['firstStep', 'secondStep','searchStep@home','contactStep@search/result'] } // Your steps order
      );
  }

  getUnredMessageCounts(identityId) {
    this.subscirption = this.chatService.unreadMessageNotification(identityId).subscribe(resp => {
      this.unreadcount = resp
    })
  }

  ngOnDestroy() {
    this.subscirption.unsubscribe();
  }
  getcurrentLocation() {
    this.subscirption = this.IdentityService.userCurrentLocation.subscribe((resposne: any) => {
      if (resposne == undefined) {
        return
      }
      this.CurrentLocation = resposne
    })
  }

  public countDoctorPendingAppointments(): void {
    let query = ''
    this.appointmentService
      .getDoctorAppointmentHistories(this.identity.identityID, query)
      .then((response) => {
        const appointments = response.items.filter(
          (x) => x.caseStatusLookup === 6 || x.caseStatusLookup === 3
        );
        this.appointmentCount = appointments.length

      })
      .catch((error) => {
        console.error(error);
      });
  }

  public countPatientPendingAppointments(): void {
    let query = ''
    this.appointmentService
      .getPatientAppointmentHistories(this.identity.identityID,query)
      .then((response) => {
        const appointments = response.items.filter(
          (x) => x.caseStatusLookup === 6 || x.caseStatusLookup === 3
        );
        this.appointmentCount = appointments.length

      })
      .catch((error) => {
        console.error(error);
      });
  }

  public countBlogs(query?: string): void {
    this.blogService.getBlogList(query).subscribe((response) => {
      const blogs = response.items;
      const isAdmin = this.identityTypeLookup === 6;
      if (isAdmin) {
        this.blogCount = blogs.length;
      } else {
        this.blogCount = blogs.filter(blog =>
          blog.enabled || (blog.authorIdentityID === this.currentIdentityId && !blog.enabled)
        ).length;
      }
    },
    (error) => {
      console.error('Error fetching blog list:', error);
    });
  }


  currentState(url: string): boolean {
    return this.currenturl.includes(url)
  }

  openDropDown(event: Event) {
    this.isDropdownOpen = true;
  }
  closeDropDown(event: Event) {
    this.isDropdownOpen = false;
  }
  public get identityTypeLookup(): number {
    const profileData = JSON.parse(localStorage.getItem('userData'))
    if (profileData) {
      return profileData.identityTypeLookup
    }


  }
  public get hasValidAuthenticationToken(): boolean {
    return this.IdentityService.hasValidAuthenticationToken()
  }
  public get getProfilePhoto(): any {
    if (this.identity && this.identity.profilePicture && !_.isNil(this.identity.profilePicture)) {
      return `data:image/jpeg;base64,${this.identity.profilePicture}`;
    }
    return this.identity.identityPhotoID ? '/assets/register.png' : '/assets/register.png';
  }
  public get identity(): IProfileData {
    if (this.hasValidAuthenticationToken) {
      return JSON.parse(localStorage.getItem('userData'))
    }
  }
  public onLogOutClick(): void {
    this.authenticationService.logout()
    this.signalRService.pushNotification.next(0)
  }

  notification() {
    this.subscirption = this.signalRService.pushNotification.subscribe(notification => {
      this.signalRService.unreadMessageCount.next(notification)
      this.ref.detectChanges()
    })

  }
  changeonSelect() {
    var lang = this.selectedLang;
    let languageSelect: any = document.querySelector('select.goog-te-combo');
    if (languageSelect) {
      languageSelect.value = lang;
      languageSelect.dispatchEvent(new Event('change'));
    }
  }
  SelectValue(code: string) {
    var lang = code;
    let languageSelect: any = document.querySelector('select.goog-te-combo');
    if (languageSelect) {
      languageSelect.value = lang;
      languageSelect.dispatchEvent(new Event('change'));
    }
  }



}



