import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { BehaviorSubject, Subject } from 'rxjs';
import { IMessage } from 'src/app/interfaces/appInterface';
import { AppStartupService } from '../app-startup.service';
import { IdentityService } from '../identity.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  public data: IMessage[];
  public chatSubject = new Subject<any>();
  public pushNotification = new BehaviorSubject<any>(0);
  public bradcastedData: IMessage[];
  private connection: any;
  private proxy: any;
  public unreadMessageCount = new BehaviorSubject<number>(0);
  public unreadMessageCount$ = this.unreadMessageCount.asObservable()
  public newUserListSubject = new Subject<any>();
  public callSubject = new Subject<any>();
  public cancelCallSubject = new BehaviorSubject(false);

  constructor(private identityService: IdentityService, private appStartupService: AppStartupService) {
  }

  public initializeNotifcationHub(): void {
    this.connection = window.jQuery.hubConnection(this.appStartupService.signalRHub);
    this.proxy = this.connection.createHubProxy('NotificationHub');

    this.proxy.on('pushNotification', (serverNotification) => {
      this.pushNotification.next(serverNotification)
    });

    this.connection.start().done((data: any) => {
      this.SubscribeNotifications(this.identityService.getActiveIdentityId())
    }).catch((error: any) => {
    });
  }

  public SubscribeNotifications(currentuserid: number) {
    this.proxy.invoke('subscribeNotifications', currentuserid).then(res =>
   {}).catch(error => {})
  }


  // chatting signalr portion
  public initializeChatHubConnection(): void {
    this.connection = window.jQuery.hubConnection(this.appStartupService.signalRHub);
    this.proxy = this.connection.createHubProxy('ChatDoctorPatient');

    this.proxy.on('clientMessage', (serverMessage) => {
      this.chatSubject.next(serverMessage)
    });

    this.proxy.on('unreadMessagesOfEach', (serverMessage) => {
      this.newUserListSubject.next(serverMessage)
    })
    this.connection.start().done((data: any) => {
      this.subscribechat(this.identityService.getActiveIdentityId())
    }).catch((error: any) => {
    });
  }

  public subscribechat(currentuserid: number) {
    this.proxy.invoke('subscribeChat', currentuserid).then(res =>
      {}).catch(error => {})
  }

  // chatting signalr portion
  public initializeVideoHubConnection(): void {
    this.connection = window.jQuery.hubConnection(this.appStartupService.signalRHub);
    this.proxy = this.connection.createHubProxy('ChatHub');

    this.proxy.on('call', (serverMessage) => {
      this.callSubject.next(serverMessage)
    });
    this.proxy.on('cancelCall', (serverMessage) => {
      this.cancelCallSubject.next(serverMessage)
      localStorage.setItem("cancelCall", serverMessage);
    });
    this.connection
      .start()
      .done((data: any) => {
        this.subscribeToCall(this.identityService.getActiveIdentityId())
      })
      .catch((error: any) => {
      });
  }

  public subscribeToCall(patientId: number) {
    this.proxy.invoke('subscribeToCall', patientId);
  }

  private broadcastMessage(): void {
    this.proxy.invoke('clientMessage').then((request) => {
      this.chatSubject.next(request)
    })
      .catch((error: any) => {
      });
  }


  private onMessageReceived(serverMessage: string) {
  }
  public send(message: string, identityId: number) {
    this.proxy.invoke('send', message, identityId)
  }
}
