import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBlogCategory, IBlogComment, IBlogData, IBlogList, IBlogPost, IBlogRequest, IBlogResponse } from 'src/app/interfaces/appInterface';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  host  = 'http://loalhost:63753/api/v1/';
  constructor(private httpClient:HttpClient, private appService: AppService) { }



  public AddNewBlog(request: IBlogData):Observable<any>{
    const req = {blogData: request}
    return this.httpClient.post (this.appService.apiUrl + 'blog/create',req)
  }
  public getBlogList(query: string = ''): Observable<{items: IBlogList[]; count: number}> {
    return this.httpClient.get<{items: IBlogList[]; count: number}>(

       this.appService.apiUrl + 'blog/bloglist' + query
    )
  }

  public getLatestBlogPosts(query: string = ''): Observable<{items: IBlogPost[]; count: number}> {
    return this.httpClient.get<{items: IBlogPost[]; count: number}>(

       this.appService.apiUrl + 'blog/latestposts' + query
    )
  }

  public getBlogListByCategory(request) {
    return this.httpClient.get(this.appService.apiUrl + 'blog/getblogbycategories', request)
  }

  public getComment(commentId: number): Observable<IBlogComment> {
    return this.httpClient.get<IBlogComment>(

       `${this.appService.apiUrl}blog/${commentId}/comment`
    )
  }

  public addComment(blogId: number, request) {
    return this.httpClient.post(
     `${this.appService.apiUrl}blog/${blogId}/addComment`,
       request
    )
  }

  public getCategories(query: string = ''): Observable<{items: IBlogCategory[]; count: number}> {
    return this.httpClient.get<{items: IBlogCategory[]; count: number}>(

       this.appService.apiUrl + 'blog/categories' + query
    )
  }

  public get(blogId: number): Observable<IBlogResponse> {
    return this.httpClient.get<IBlogResponse>(

       `${this.appService.apiUrl}blog/${blogId}`
    )
  }

  public deleteComment(blogId: number, commentId: number) {
    return this.httpClient.delete(

       `${this.appService.apiUrl}blog/${blogId}/comment/${commentId}`
    )
  }

  public deleteBlog(blogId: number) {
    return this.httpClient.delete(

       `${this.appService.apiUrl}blog/${blogId}/delete`
    )
  }
  public updateBlog(blogId: number, request: IBlogRequest) {
    return this.httpClient.put < IBlogData >(
  `${this.appService.apiUrl}blog/${blogId}/update`, request)
  }


}
