import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IdentityService } from './Services/identity.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private identityService:IdentityService, private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.identityService.hasValidAuthenticationToken()){
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false
      }
      return this.identityService.hasValidAuthenticationToken();
  }

}
