import { Injectable } from '@angular/core';
import { IAddIdentityPhotosRequest, IAddIdentityPhotosResponse, IContactAdminRequest, IGeneratePasswordResetTokenRequest, IGetIdentityPhotoResponse, IGetIdentityPhotosResponse, IGetProfileResponse, IIdentityPhoto, IIsProfileCompletedRequest, IIsProfileCompletedResponse, ILocation, IProfileData, IResetPasswordRequest, IupdatedIdentityPhoto, IUpdatePersonalDetailRequest, IUserToken } from '../interfaces/appInterface';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from './app.service';


@Injectable({
  providedIn: 'root'
})


export class IdentityService {
  public userCurrentLocation: BehaviorSubject<ILocation> = new BehaviorSubject<ILocation>({
    address_components:[],
    formatted_address:  '',
    geometry: {},
    place_id: '',
    plus_code:{},
    types: [],
   });

  private cachedTokenWhichWasDecoded: string = ''
  private cachedDecodedToken: IUserToken | null = null
  public  jwtHelper = new JwtHelperService();


  constructor(private httpClient: HttpClient, private appService: AppService) { }

  public decodeToken(): IUserToken | Partial<IUserToken> {
    const token = localStorage.getItem('authenticatedByLoginToken')
    if (!token) {
      return {}
    }
    if (token !== this.cachedTokenWhichWasDecoded) {
      this.cachedTokenWhichWasDecoded = token
      this.cachedDecodedToken = this.jwtHelper.decodeToken(token)
    }
    return this.cachedDecodedToken
  }

  public getActiveIdentityId(): number {
    const userId = this.decodeToken().IdentityDbId
    if (userId) {
      return Number.parseInt(userId)
    }

  }

  public getActiveDoctorId(): number {
    const profileData = JSON.parse(localStorage.getItem('userData'))
    if (profileData) {
      return profileData.doctorID
    }
  }

  public getActiveUserEmail(): string {
    const profileData = JSON.parse(localStorage.getItem('userData'))
    if (profileData) {
      return profileData.email
    }
  }

  public getActiveUser(): IProfileData {
    return JSON.parse(localStorage.getItem('userData'))
 }

  public isProfileComplete(request: IIsProfileCompletedRequest): Promise<IIsProfileCompletedResponse> {
    return this.httpClient.get<IIsProfileCompletedResponse>( `${this.appService.apiUrl}identity/isprofilecompleted?identityId=${request.identityId}&isDoctor=${request.isDoctor}&isPatient=${request.isPatient}&isVisitor=${request.isVisitor}`).toPromise()

}

public getPasswordResetToken(request: IGeneratePasswordResetTokenRequest): Promise<any> {
  return this.httpClient.post<any>( `${this.appService.apiUrl}authentication/resetToken`, request).toPromise()

}

public resetPassword(request: IResetPasswordRequest, token): Promise<any> {
  return this.httpClient.post<any>( `${this.appService.apiUrl}authentication/resetPassword`, request, {headers: {'Authorization':'JWT '+token}}).toPromise()

}
public hasValidAuthenticationToken(): boolean {
  try {
    const authenticationToken = localStorage.getItem('authenticatedByLoginToken')
    // console.log("valid token ", authenticationToken !== null && !this.jwtHelper.isTokenExpired(authenticationToken))
    return authenticationToken !== null && !this.jwtHelper.isTokenExpired(authenticationToken)
  } catch (err) {
    return false
  }
}
public hasValidPasswordResetToken(token): boolean {
  try {
    const passwordResetToken = token
    return passwordResetToken !== null && !this.jwtHelper.isTokenExpired(passwordResetToken)
  } catch (err) {
    return false
  }
}

public addPhoto(request: any): Promise<any> {
  return this.httpClient.post<any>(`${this.appService.apiUrl}identity/photo/add`, request).toPromise()
}

public getPhoto(identityId: number): Promise<IGetIdentityPhotoResponse> {
  return this.httpClient.get<IGetIdentityPhotoResponse>(`${this.appService.apiUrl}identity/${identityId}/photo`)
    .toPromise()
}

public deletePhoto(identityId,photoid) {
  return this.httpClient.delete(
    `${this.appService.apiUrl}identity/${identityId}/photo/${photoid}`)
  }
public updatePhoto(identityId: number, photoid:number ,request) {
  return this.httpClient.put(
`${this.appService.apiUrl}identity/${identityId}/photo/${photoid}`,request)
 }
 public getProfileByIdentityId(identityId: number): Observable<any> {
  return this.httpClient.get<any>(
    `${this.appService.apiUrlV2}newt2d/${identityId}/getprofile`
  )
}
public updatePersonalDetail(identityId: number, request: IUpdatePersonalDetailRequest) {
  return this.httpClient.put(`${this.appService.apiUrl}identity/${identityId}/update`,request)
}
public contactAdmin(request: IContactAdminRequest) {
  return this.httpClient.post(
 `${this.appService.apiUrl}identity/contact`, request
  )
}

apiKey= 'AIzaSyB1CaF3uz9Mv27pssBj4l-j5jc_-AQLrdU'
public getAddress(lat,lng) {
  return this.httpClient.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.apiKey}
  `)
}

  }
