import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { IdentityService } from './identity.service';
import { tap } from 'rxjs/operators';
import { AppService } from './app.service';


const Urls = {
  requestResetPasswordTokenPath: 'authentication/resettoken',
  pingPath: 'session/ping',
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  email = ''

  constructor(private http: HttpClient,
    private identityService: IdentityService,
    private appService: AppService) { }

  getToken(): string {
    return localStorage.getItem('authenticatedByLoginToken')
  }

  refreshToken() {
    this.email = this.identityService.getActiveUserEmail();
    const reqBody = {
      email: this.email
    }
    return this.http.post<any>(`${this.appService.apiUrl}authentication/resetToken`, reqBody).pipe(tap((tokens) => {
      this.storeJwtToken(tokens);
    }));
  }

  ping(){
        return this.http
      .get(`${this.appService.apiUrl}${Urls.pingPath}`)
      .toPromise()
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem('authenticatedByLoginToken', jwt);
  }

}
