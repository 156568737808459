import { Component, OnInit, ChangeDetectorRef, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { IBlogPost, ICountSpecility, IGetProfileResponse, IHomePageSliderList, IProfileData, ITopRatedDoctors } from '../interfaces/appInterface';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IdentityService } from '../Services/identity.service';
import { SignalrService } from '../Services/signalr/signalr.service';
import { IdentityType } from '../enums/enums';
import { ICallDTO } from '../Services/chat-service/chat-service';
import moment from 'moment'
import { DoctorCard, HomePageImages, LatestBlog, topRatedDoctor, userAddress } from '../ngrxStore/models/home.model';
import { Store } from '@ngrx/store';
import { AppState, AppState2, AppState3, AppState4, AppState5 } from '../ngrxStore/models/app-state.model';
import { LoadBlogAction, LoadDoctorAction, LoadHomePageImagesAction, LoadTopDoctorsAction, LoadUserAddressAction } from '../ngrxStore/actions/home.action';
import { Observable, Subscription } from 'rxjs';
import { ChatService } from '../Services/chat-service/chat-service';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { Key } from 'protractor';
import { AuthenticationService } from '../Services/authentication.service';
import { LocalstorageService } from '../Services/localstorage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  title = 'Home Page';
  subString1: number = 70
  subString2: number = 70
  subString3: number = 50
  keyword = 'name';
  place = 'Search Doctors By Name, Speciality, Degree & Location';
  searchValue: string = '';
  data: string[] = []
  countspecility: ICountSpecility[];
  slideToshow: number = 4;
  showTab: number = 1;
  iconUrl = {
    url: '/assets/MarkerImage.png'
  }
  topRatedDoctors: ITopRatedDoctors[];
  latitude: number;
  longitude: number;
  zoom: number;
  userLocation: { latitude: any; longitude: any };
  userData: IProfileData;
  incomingCall: any;
  callPopUp: boolean;
  DoctorList: Observable<DoctorCard>;
  loading$: Observable<Boolean>;
  error$: Observable<Error>
  public subscription = new Subscription()
  recentBlogPosts: IBlogPost[]
  RecentBlogPosts: Observable<LatestBlog>;
  blogloading$: Observable<Boolean>;
  blogerror$: Observable<Error>
  sliderloading$: Observable<Boolean>;
  slidererror$: Observable<Error>
  TopDoctorsList: Observable<topRatedDoctor>;
  TopDoctorsloading$: Observable<Boolean>;
  TopDoctorserror$: Observable<Error>
  UserAddressList: Observable<userAddress>;
  UserAddressloading$: Observable<Boolean>;
  UserAddresserror$: Observable<Error>;
  slidesResp: Observable<HomePageImages>
  slides: IHomePageSliderList[] = [{sliderTypeLookup:3,image:'',title:''}];
  ringTone = new Audio()
  topCardImages: IHomePageSliderList[]

  constructor(
    private toaster: ToastrService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private signalrService: SignalrService,
    private ref: ChangeDetectorRef,
    private identityService: IdentityService,
    private ChatService: ChatService,
    private store: Store<AppState>,
    private store2: Store<AppState2>,
    private store3: Store<AppState3>,
    private store4: Store<AppState4>,
    private titleService: Title,
    private metaTagService: Meta,
    private store5:Store<AppState5>,
    private authenticationService: AuthenticationService,
    private localStorageService: LocalstorageService
  ) { }

  ngOnInit(): void {
    this.seoTag()
    this.userAddress()
      this.userData = JSON.parse(localStorage.getItem('userData'));
    if (this.userData && this.userData.identityTypeLookup == IdentityType.Patient) {
      this.subscribeCall(this.userData.identityID);
    }
    this.checkDoctorProfileVerify()
    this.setCurrentLocation();
    const isMobile = this.deviceService.isMobile();
    if (isMobile) {
      this.slideToshow = 1
      this.doctorSlide = {
        "nextArrow": '<i class="fa fa-chevron-right nextone" aria-hidden="true"></i>',
        "prevArrow": '<i class="fa fa-chevron-left previousone" aria-hidden="true" style="color: red; background-color: blue;"></i>',
        "slidesToShow": this.slideToshow,
        "slidesToScroll": 1,
      }
    }
     this.populateRecentPosts()
     this.populateDoctorsList();
     this.doctorList();
     this.populateSliderImages();

  }
  seoTag() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Search Doctor to find the best doctor in your area' }
    );
  }
  doctorList() {
    this.DoctorList = this.store.select(store => store.doctor.list);
    this.loading$ = this.store.select(store => store.doctor.loading);
    this.error$ = this.store.select(store => store.doctor.error);
    this.store.dispatch(new LoadDoctorAction())
    this.DoctorList.subscribe((response) => {
      this.data = []
      response.doctors.map(d => {
        this.data.push(d.title)
        if (d.doctorSpecilities) {
          d.doctorSpecilities.map(s => {
            const names = this.data.map(x => x)
            if (s.name != null && !(names.includes(s.name))) {
              this.data.push(s.name)
            }
          })
        }
        if (d.doctorDegrees) {
          d.doctorDegrees.map(deg => {
            const names = this.data.map(x => x)
            if (deg.name != null && !(names.includes(deg.name))) {
              this.data.push(deg.name)
            }
          })
        }
      })
    })
  }
  userAddress() {
    this.UserAddressList = this.store4.select(store => store.userSpecificAddress.list);
    this.UserAddressloading$ = this.store4.select(store => store.userSpecificAddress.loading);
    this.UserAddresserror$ = this.store4.select(store => store.userSpecificAddress.error);
    this.store.dispatch(new LoadUserAddressAction())
    this.UserAddressList.subscribe((response) => {
      let LocationCity = response.results[0]
      this.identityService.userCurrentLocation.next(LocationCity)
    }, (error) => {

    })
  }
  showFullString(stringId: number) {
    if (stringId == 1) {
      this.subString1 = 1000

    }
    if (stringId == 2) {
      this.subString2 = 1000

    }
    if (stringId == 3) {
      this.subString3 = 1000

    }
  }
  showLessString(stringId: number) {
    if (stringId == 1) {
      this.subString1 = 70

    }
    if (stringId == 2) {
      this.subString2 = 70

    }
    if (stringId == 3) {
      this.subString3 = 50

    }
  }

  public subscribeCall(patientIdentityID) {
    this.signalrService.initializeVideoHubConnection()
    this.signalrService.callSubject.subscribe((callDTO) => {
      this.incomingCall = callDTO;
      if (!_.isNil(this.incomingCall)) {
        this.playAudio();
        this.callPopUp = false;
      } else {
        this.callPopUp = true;
        this.ref.detectChanges();
      }
    });
  }

  playAudio() {
    this.ringTone.src = "../../assets/calling-tone.mp3";
    this.ringTone.load()
    this.ringTone.play()
  }

  public get hasIncomingCall() {
    return !_.isNil(this.incomingCall)
  }

  public accepteCall() {
    this.ringTone.pause();
    this.openVideoChatWindow(this.incomingCall)
    this.incomingCall = null

  }

  public openVideoChatWindow(callDTO: ICallDTO): void {
    if (_.isNil(callDTO)) {
      callDTO = {
        calleeId: this.incomingCall.calleeId,
        callerId: this.incomingCall.callerId,
        appointmentId: this.incomingCall.appointmentId,
        peerId: this.incomingCall.peerId
      }
    }
    //this.populateDoctorsList(userLocation)
    // this.router.navigate([`video-chat/${JSON.stringify(callDTO)}`])
    window.open(`video-chat/${JSON.stringify(callDTO)}`, 'Video Call', 'width=1100, height=700, left=200')
  }

  public declineCall() {
    this.ringTone.pause();
    this.ChatService.closeCall(this.incomingCall.callerId)
    this.incomingCall = null
  }

  private setCurrentLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 15;
          this.userLocation = {
            longitude: this.longitude,
            latitude: this.latitude,
          };
        });
      }
  }
  doctorSlide = {
    nextArrow:
      '<button type="button" data-role="none" class="slick-arrow slick-next" style="background-color: #24badc; width: 50px; height:50px;' +
      'right: -50px; display: block; " > </button>',
    prevArrow:
      '<button type="button" data-role="none" class="slick-arrow slick-prev" style="background-color: #24badc; width: 50px; height:50px;' +
      ' display: block; left:-50px;" > </button>',
    slidesToShow: this.slideToshow,
    slidesToScroll: 1,
  };
  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": true,
    "infinite": true,
    "autoplaySpeed": 3000,
    "autoplay": true,
    "swipeToSlide": true
  };
  blogs = []
  selectEvent(item) {
    // do something with selected item
    this.searchValue = item
  }

  tabToggle(index: number): any {
    return (this.showTab = index);
  }
  tabtoggleplus(index: number): any {
    if (index > 3) {
      return (this.showTab = 1);
    } else return (this.showTab = index + 1);
  }
  toggletabmminus(index: number): any {
    if (index == 1) {
      return (this.showTab = 4);
    } else return (this.showTab = index - 1);
  }

public populateRecentPosts(): void {
  this.userData = JSON.parse(localStorage.getItem('userData'))
  const isAdmin = this.userData && this.userData.identityTypeLookup === 6
  this.RecentBlogPosts = this.store2.select(store => store.blog.list);
  this.blogloading$ = this.store2.select(store => store.blog.loading);
  this.blogerror$ = this.store2.select(store => store.blog.error);
  this.store.dispatch(new LoadBlogAction())
  this.RecentBlogPosts.subscribe((response) => {
    if (response && response.items) {
      this.recentBlogPosts = isAdmin
        ? response.items.slice(0, 4)
        : response.items.filter(post => post.enabled === true).slice(0, 4)
    } else {
      this.recentBlogPosts = []
    }
  }, (error) => {
    console.error("Error loading recent blog posts:", error)
  })
}



public populateSliderImages(): void {
   this.slidesResp = this.store5.select(store => store.hImage.list);
  this.sliderloading$ = this.store5.select(store => store.hImage.loading);
  this.slidererror$ = this.store5.select(store => store.hImage.error);
  this.store.dispatch(new LoadHomePageImagesAction())
  this.slidesResp.subscribe((response)=>{
    this.slides = []
        this.slides = response.homePageSliderList.filter(sl => sl.sliderTypeLookup === 3)
        this.topCardImages = response.homePageSliderList.filter(sl => sl.sliderTypeLookup === 1)
},(error)=>{})
}
momentDate(date) {
  return moment(date).format('DD/MM/yyyy')
}
public bookAppointment(docId: number){
  if(!this.hasValidAuthenticationToken){
    this.toaster.info("Please Login Before Book Appointment to Doctor")
  }
  this.router.navigate(['/make-appointment'], { queryParams: { doctorId: docId } })
}

  public get hasValidAuthenticationToken(): boolean {
    let abc = this.identityService.hasValidAuthenticationToken();
    return abc
  }
  onChangeSearch($event: KeyboardEvent) {

    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  SearchEnter() {
    this.Search()
  }
  onFocused(e) {
    // do something when input is focused
  }
  Search() {
    this.router.navigate(['/search/result'], {
      queryParams: { keyword: this.searchValue },
    });
  }
  public populateDoctorsList(): void {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.TopDoctorsList = this.store3.select(store => store.topDoctors.list);
    this.TopDoctorsloading$ = this.store3.select(store => store.topDoctors.loading);
    this.TopDoctorserror$ = this.store3.select(store => store.topDoctors.error);
    this.store.dispatch(new LoadTopDoctorsAction())
    this.TopDoctorsList.subscribe((response) => {
      const isAdmin = this.userData && this.userData.identityTypeLookup === 6;
      this.countspecility = response.countDoctorBySpeciality;
      this.countspecility = _.take(
        _.sortBy(this.countspecility, (x) => x.totalDoctors).reverse(),
        8
      );
      if(isAdmin){
        this.topRatedDoctors = response.doctorsList;
      } else {
        this.topRatedDoctors = response.doctorsList.filter(doctor => doctor.isPublished === true);
      }
      this.topRatedDoctors = _.take(
        _.sortBy(this.topRatedDoctors, (x) => x.doctorStarRatting).reverse(),
        8
      );
      // this.topRatedDoctors.map(d=> d.clinicDays.length ? d.nextAvailability = d.clinicDays.find(c=> c.clinicDayStatusLookup === 1): '' )

    }, (error) => {})
  }

  public getLocalDateTime(date: string): string {
    return moment(date).format('DD/MM/yyyy hh:mm a')
  }
  public getLocalTime(date: string): string {
    if (!date) {
      return
    }
    return moment.utc(date).format('hh:mm a')
  }
  public covertPhotoUrl(photoUrl) {
    return `data:image/jpeg;base64,${photoUrl}`;
  }

  changeFn(event) {
    // changes.prop contains the old and the new value...
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  checkDoctorProfileVerify() {
    if (this.userData && this.userData.isPublished == false) {
      this.authenticationService.getIdentityProfile().then((response:IGetProfileResponse)=>{
         if (response.profileData.isPublished == true) {
           this.localStorageService.IsPublish = true
         }
      }).catch(error=>{})

    }

  }
}
