import {  NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './authentication.guard';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './Services/auth-gaurd.service';

const routes: Routes = [
  { path: '', loadChildren: () => import(`./home/home.module`).then(m => m.HomeModule)},
  { path: 'home', loadChildren: () => import(`./home/home.module`).then(m => m.HomeModule)},
 { path: 'survey', loadChildren: () => import(`./surveys/surveys.module`).then(m => m.SurveysModule) },
 { path: 'blog', loadChildren: () => import(`./blogs/blogs.module`).then(m => m.BlogsModule) },
 { path: 'addblog', loadChildren: () => import(`./add-blog/add-blog.module`).then(m => m.AddBlogModule), canActivate:[AuthenticationGuard]},
 { path: 'contact', loadChildren: () => import(`./contact/contact.module`).then(m => m.ContactModule) },
 { path: 'about', loadChildren: () => import(`./about-us/about-us.module`).then(m => m.AboutUsModule) },
 { path: 'login', loadChildren: () => import(`./login/login.module`).then(m => m.LoginModule) },
 { path: 'prefrence', loadChildren: () => import(`./prefrences/prefrences.module`).then(m => m.PrefrencesModule), canActivate:[AuthenticationGuard]},
 { path: 'appointment', loadChildren: () => import(`./appointment-dashboard/appointment-dashboard.module`).then(m => m.AppointmentDashboardModule),  canActivate:[AuthenticationGuard] },
 { path: 'make-appointment', loadChildren: () => import(`./appointment/appointment.module`).then(m => m.AppointmentModule), canActivate:[AuthenticationGuard] },
 { path: 'video-chat/:calldto', loadChildren: () => import(`./video-chat-view/video-chat-view.module`).then(m => m.VideoChatViewModule), canActivate:[AuthenticationGuard] },
 { path: 'profile/:identityId', loadChildren: () => import(`./profile/profile.module`).then(m => m.ProfileModule), canActivate:[AuthenticationGuard] },
 { path: 'changePassword/:identityId', loadChildren: () => import(`./change-password/change-password.module`).then(m => m.ChangePasswordModule), canActivate:[AuthenticationGuard] },
 { path: 'search', loadChildren:() => import(`./search-doctor/search-doctor.module`).then(m => m.SearchDoctorModule)},
 { path: 'take-appointment/:medicalRecordId', loadChildren:() => import(`./take-appointment/take-appointment.module`).then(m => m.TakeAppointmentModule), canActivate:[AuthenticationGuard]},
 { path: 'report-generation/:medicalRecordId/:isDefault',loadChildren:() => import(`./report-generation/report-generation.module`).then(m => m.ReportGenerationModule), canActivate:[AuthenticationGuard]},
 { path: 'chat', loadChildren:() => import(`./chat/chat.module`).then(m => m.ChatModule), canActivate:[AuthenticationGuard] },
 { path: 'doctorProfile/:identityId', loadChildren:() => import(`./doctor-profile-view/doctor-profile-view.module`).then(m => m.DoctorProfileViewModule)},
 { path: 'blogdetail/:blogId', loadChildren: () => import(`./blog-detail/blog-detail.module`).then(m => m.BlogDetailModule) },
 { path: 'updateblog/:blogId', loadChildren: () => import(`./update-blog/update-blog.module`).then(m => m.UpdateBlogModule), canActivate:[AuthenticationGuard] },

 { path: '', component: HomeComponent, pathMatch: 'full' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, /*{preloadingStrategy: PreloadAllModules}*/)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {
  }
 }
